import { inject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'common/server';
import { Logger } from 'common/ui';
import { Security } from 'common/security';
import { Page } from 'common/ui';
import { I18n } from 'common/i18n';
import { Roles } from 'services/roles';
import { ROLE } from 'common/constants';

@inject(EventAggregator, Security, Api, Logger, Page, I18n, Roles)
export class SearchMembers {
    _i18n;
    _roles;

    searchKey = 'lpfn.memberSearchKey';
    searchWithinMemberKey = 'lpfn.memberSearchWithinMemberKey';

    searchOn = '';

    showFilterOptions = false;

    roleIds;
    roleId;
	includeOnlyAgencies;
	includeOnlyTeams;

    pageSize = 10

	isProcessing = false;
	searchResults = [];
	searchComplete = false;
	searchModel = null;

	fromMyTeamPage = false;
	displayAdminLinks = false;
	searchWithinMember = null;

	modalTitle = '';
	modalModel = null;
	modalView = '';

	constructor(eventAggregator, security, api, logger, page, i18n, roles) {
		this.eventAggregator = eventAggregator;
		this.security = security;
		this.api = api;
		this.logger = logger;
		this.page = page;
		this._i18n = i18n;
        this._roles = roles;
	}

	async attached() {
	    $('#search-members-modal').on('hidden.bs.modal', this.clearModal);

        await this._loadOptions();

	    var ele = $('search-members');
		if (ele.length > 0) this.fromMyTeamPage = ele.hasClass('search-my-team-only');
		this.displayAdminLinks = this.security.isAdmin && !this.fromMyTeamPage;

		let lastSearch = this._fromLocalStorage(this.searchKey);
		this.searchWithinMember = this._fromLocalStorage(this.searchWithinMemberKey);

        if (lastSearch && lastSearch.model && lastSearch.options) {
			this.searchModel = lastSearch.model;
			this.searchOn = lastSearch.options.searchOn;
			if (lastSearch.options.roleId) this.roleId = lastSearch.options.roleId;
			if (lastSearch.options.includeOnlyAgencies === true) this.includeOnlyAgencies = true;
			if (lastSearch.options.includeOnlyTeams === true) this.includeOnlyTeams = true;
			if (this.roleId || this.includeOnlyAgencies === true || this.includeOnlyTeams === true) this.showFilterOptions = true;
            window.setTimeout(() => this.trySearch(), 0);
		} else {
			localStorage.removeItem(this.searchKey);
		}
	}

	clearSearch() {
		try {
			if (this.roleId) this.roleId = undefined;
			this.includeOnlyAgencies = false;
			this.includeOnlyTeams = false;
			this.searchOn = '';
			this.setSearchParameters();
			this.searchResults = [];
		} catch (err) {
			console.log(err);
		}
	}

    async _loadOptions() {
        try {
            this.roleIds = await this._roles.all();
        } catch (err) {
            console.log(err);
        }
    }

	toggleFilterOptions() {
	    this.showFilterOptions = !this.showFilterOptions;
	}

	_fromLocalStorage(key) {
	    try {
	        let value = localStorage.getItem(key);
	        if (!value) return null;
	        if (value === 'null') return null;
            if (value.indexOf('{') === 0) return JSON.parse(value);
	        return value;
        } catch(ex) {
            return null;
        }
	}

	setSearchParameters() {
		var downlineOfMemberId = null;
		if (this.searchWithinMember != null) downlineOfMemberId = this.searchWithinMember.id;

		this.searchModel = {
			searchOn: this.searchOn,
			downlineOfMemberId: downlineOfMemberId,
			roleId: this.roleId
		};
		if (this.includeOnlyAgencies === true) this.searchModel.includeOnlyAgencies = true;
		if (this.includeOnlyTeams === true) this.searchModel.includeOnlyTeams = true;

		var lastSearch = {
			model: this.searchModel,
			options: {
				searchOn: this.searchOn,
				roleId: this.roleId,
			}
		};
		if (this.includeOnlyAgencies === true) lastSearch.options.includeOnlyAgencies = true;
		if (this.includeOnlyTeams === true) lastSearch.options.includeOnlyTeams = true;
		localStorage.setItem(this.searchKey, JSON.stringify(lastSearch));
		localStorage.setItem(this.searchWithinMemberKey, this.searchWithinMember);
}

	async trySearch() {
		if (this.isProcessing) return;

        try {
            this.searchResults = [];
            this.isProcessing = true;

			this.setSearchParameters();

            await this.doSearch();
        } catch (err) {
            console.log(err);
            this.isProcessing = false;
        }
	}

	async doSearch() {
        try {
            let url = 'member/search-my-downline';
            if (this.security.isAdmin && !this.fromMyTeamPage) url = 'member/search';
            if (this.security.isInRole(ROLE.HierarchyViewer)) url = 'member/search';

            this.searchResults = await this.api.post(url, this.searchModel);
		    this.searchComplete = true;
		} catch (err) {
		    console.log(err);
		} finally { 
		    this.isProcessing = false;
		}
	}

	exportResults() {
	    this.page.exportData(this.api, 'member/export-search-key', 'member/export-search', this.searchModel)
            .then(response => { /*nothing...already handled*/ })
            .catch(error => { console.log(error); });
	}

	searchWithinDownlineOf(member) {
		this.searchWithinMember = member;
		this.trySearch();
	}

	removeSearchWithinDownlineOf() {
		this.searchWithinMember = null;
		localStorage.removeItem(this.searchWithinMemberKey);
		this.trySearch();
	}

	showProfile(memberId) {
	    this.eventAggregator.publish("lpfn.show-member-profile-popup", { member: null, memberId: memberId });
	}

	clearModal() {
	    this.modalTitle = '';
	    this.modalModel = null;
	    this.modalView = '';
	}

	showHierarchy(member) {
	    //console.log('show hierarchy for ' + member.firstName);
	    this.modalTitle = member.firstName + ' ' + member.lastName + ' Hierarchy';
	    this.modalModel = { id: member.id };
	    this.modalView = './hierarchy-full';
	    $('#search-members-modal').modal('show');
	}
}
 