import { PLATFORM } from 'aurelia-pal';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Page } from 'common/ui';
import { Carriers } from 'services/carriers';
import { I18n } from 'common/i18n';
import { CarrierEditor } from './dialog/carrier-editor';
import { DialogService } from 'aurelia-dialog';
import { ROLE } from 'common/constants';
import { c } from 'common/common';
import environment from '../../../config/environment.json';
PLATFORM.moduleName('./dialog/carrier-editor');

export class Carrier {
    static inject = [EventAggregator, Security, Page, Carriers, I18n, DialogService];

    carriers = [];
    carrier = null;
    carrierKey;

    CarrierAdminRole = ROLE.CarrierAdmin;
    isCarrierAdmin = false;

    _handlers = [];

    constructor(ea, security, page, carriers, i18n, dialogService) {
        this._ea = ea;
        this._security = security;
        this._page = page;
        this._carriers = carriers;
        this._i18n = i18n;
        this._dialogService = dialogService;

        this.isCarrierAdmin = this._security.isInRole(ROLE.CarrierAdmin);
    }

    activate(params) {
        const newSlug = this.slug && this.slug !== params.slug;
        this.slug = params.slug
        if (newSlug) this.viewCarrier(this.carriers.find(x => x.slug === this.slug));
        this._page.track(`members/carrier/${this.slug}`);
    }

    async attached() {
        this._handlers.push(this._ea.subscribe('lpfn.carrier.logo-updated', (data) => {
            if (!this.carrier) return;
            if (this.carrier.id !== data.id) return;
            this.carrier.logoUrl = `${data.logoUrl}?cacheBuster=${(new Date()).getTime()}`;
        }));
        await this._load();
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    async _load(loadId) {
        try {
            this.carriers = await this._carriers.all();
            this.carriers.forEach(c => {
                if (this.isCarrierAdmin) c.display = true;
                else c.display = c.isActive;
            });
            let carrier = loadId
                ? this.carriers.find(x => x.id === loadId)
                : this.carriers.find(x => x.slug === this.slug);
            if (!carrier) carrier = this.carriers[0];
            this.viewCarrier(carrier);
        } catch (err) {
            console.log(err);
        }
    }

    viewCarrier(carrier) {
        if (!carrier) return;
        this.carrier = carrier;
        this.carrierLogoUrl = `${environment.static}/${this.carrier.logoUrl}`;
        this.carrierKey = `carrier-${this.carrier.id}`;
        this.showProductData = this.carrier.suranceBayData.affiliations.find(x => x === this._security.agent.suranceBayAffiliation) !== undefined;
        window.setTimeout(() => this._ea.publish(c.EventKeys.site.setPageTitle, { title: this.carrier.name }), 0); // must be after the navigation event
        return true;
    }

	openEditor(carrier) {
	    this._dialogService.open({ viewModel: CarrierEditor, model: carrier, ignoreTransitions: true }).whenClosed(async(response) => {
	        if (response.wasCancelled) return;
	        this.carriers = [];
            if (response.output.carrierDeleted) this.carrier = undefined;
	        await this._load(response.output.id);
            if (!response.output.id) return;
            const newCarrier = this.carriers.find(x => x.id === response.output.id);
            this.viewCarrier(newCarrier);
            this.openEditor(newCarrier);
	    });
	}

    isJotform(src) {
        return src.indexOf('https://form.jotform.com') === 0;
    }

    openLightbox(src) {
        const link = c.Helpers.replaceVariables(this._security, src, true);
        this._ea.publish(c.EventKeys.site.openLightbox, { embedSrc: link });
    }
}
