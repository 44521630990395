import { Nylas } from 'services/nylas';

export class AddressBook {
    static inject = [Nylas];
    _nylas;

    addressBookFilters = [{ value: '', keys: ['firstName', 'lastName', 'company', 'email', 'phone'] }];

    constructor(nylas) {
        this._nylas = nylas;
    }

    attached() {
        this._load();
    }

    async _load() {
        try {
            this.addressBook = await this._nylas.addressBook();
        } catch(err) {
            console.log(err);
        }
    }
}