import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import Dropzone from 'dropzone';
import { Notifier, Page } from 'common/ui';
import { Security } from 'common/security';
import { I18n } from 'common/i18n';
import { MemberFiles } from 'services/member-files';
import { Api } from 'common/server';
import environment from '../../../../config/environment.json';

export class MyFiles {
    static inject = [Element, EventAggregator, Notifier, Page, Security, I18n, MemberFiles, Api]
    _ea;
    _notifier;
    _page;
    _security;
    _i18n;
    _memberFiles;
    _api;

    @bindable memberId;
    @bindable singleUpload;
    @bindable showTitle = true;
    @bindable theme = 'light';
    @bindable showExpander = true;
    dropzoneEl;
    formAction;
    dropzone = null;
    fileTypes = [];
    isAttached = false;

    constructor(element, eventAggregator, notifier, page, security, i18n, memberFiles, api) {
        this._element = element;
        this._ea = eventAggregator;
        this._notifier = notifier;
        this._page = page;
        this._security = security;
        this._i18n = i18n;
        this._memberFiles = memberFiles;
        this._api = api;
    }

    attached() {
        this.isAttached = true;
        if (this.showExpander === false) this.showDetails = true;
        this._initialize();
    }

    detached() {
        if (!this.dropzone) return;
        try {
            this.dropzone.destroy();
            this.dropzone = undefined;
        } catch (err) {
            console.log(err);
        }
    }

    memberIdChanged() {
        this._initialize();
    }

    async _initialize() {
        if (!this.memberId || !this.isAttached) return;
        if (!this.fileTypes.length) {
            const fileTypes = this._memberFiles.fileTypes();
            if (this.singleUpload) {
                const singleUploadFileType = fileTypes.find(x => x === this.singleUpload);
                this.fileTypes = [{ code: singleUploadFileType }];
            } else {
                fileTypes.forEach(ft => this.fileTypes.push({ code: ft }));
            }
            this.fileTypes.forEach(ft => ft.name = this._i18n.tr(`my-files-type-${ft.code}`));
        }
        this._initializeDropzone();
        await this._load();
    }

    async _load() {
        try {
            this.files = [];
            const files = await this._memberFiles.list(this.memberId);

            if (this.singleUpload) {
                const hasSingleUploadFileType = files.find(x => x.fileType === this.singleUpload);
                if (hasSingleUploadFileType) {
                    this.files.push(hasSingleUploadFileType);
                    this._element.dispatchEvent(new CustomEvent('loaded', { bubbles: true, detail: {} }));
                }
                this._displaySingleFileUpload();
            } else {
                this.files = files;
            }
        } catch (err) {
            console.log(err);
        }
    }

    _displaySingleFileUpload() {
        const fileType = this.fileTypes.find(x => x.code === this.singleUpload);
        this.selectFileType(fileType);
    }

    cancelUpload() {
        this.selectFileType(undefined);
    }

    selectFileType(fileType) {
        this.fileType = fileType;
        if (this.fileType) {
            const memberIdQs = this.memberId ? `?forMemberId=${encodeURIComponent(this.memberId)}` : '';
            this.formAction = `${environment.api}/api/member-file/upload/${fileType.code}${memberIdQs}`;
            if (this.dropzone) {
                this.dropzone.options.url = this.formAction;
            }
        }
    }

    async viewFile(file) {
        try {
            const model = { id: file.id };
            await this._page.export(this._api, 'member-file/export-file', model);
        } catch (error) {
            this._notifier.generalError();
        }
        return false;
    }

    async deleteFile(index, file) {
        try {
            await this._memberFiles.delete(this.memberId, file.id);
            this.files.splice(index, 1);
        } catch (error) {
            this._notifier.generalError();
        }
        return false;
    }

    _initializeDropzone() {
        this.dropzone = undefined;
        if (!this.isAttached || !this.memberId) return;

        const me = this;
        let d = new Date();
        const timezoneOffset = d.getTimezoneOffset();

        this.dropzone = new Dropzone(this.dropzoneEl, {
            paramName: 'file',
            maxFilesize: 50, // MB
            headers: { 'Authorization': `Bearer ${this._security.token}`, 'X-LEGACY-TimezoneOffset': timezoneOffset },
            acceptedFiles: '.pdf',
            maxFiles: 1,
            timeout: 180000,
            init: function() {
                this.on('addedfile', function(file) {
                    me.processing = true;
                });
                this.on('success', function(file, response) {
                    this.removeFile(file);
                    me._notifier.success('my-files-upload-success');
                    me.processing = false;
                    me.cancelUpload();
                    me._load();
                    me._element.dispatchEvent(new CustomEvent('uploaded', { bubbles: true, detail: {} }));
                });
                this.on('error', function(file, error, x) {
                    this.removeAllFiles();
                    me._notifier.error('my-files-upload-failed', true);
                    me.isProcessing = false;
                });
            }
        });
    }

    toggleDetails() {
        this.showDetails = !this.showDetails;
    }
}

