import {inject} from 'aurelia-framework';
import {Page} from 'common/ui';

@inject(Page)
export class Contests {

	constructor(page) {
		this.page = page;
	}

	activate() {
		this.page.track('members/contests');
	}

	attached() {
	}

}