import { bindable } from 'aurelia-framework';
import { Members } from 'services/members';

export class Suppression {
    static inject = [Members];

    @bindable memberId;
    isSuppressed;

	constructor(members) {
        this._members = members;
    }

	memberIdChanged() {
        if (!this.memberId) return;
        this._load();
	}

	async _load() {
        try {
            this.isSuppressed = await this._members.isSuppressed(this.memberId);
        } catch (err) {
			console.log(err);
		}
	}

    async toggleSuppression(event) {
        try {
            await this._members.setSuppression(this.memberId, event.detail.checked);
        } catch (err) {
            console.log(err);
        }
    }
}
