import { observable } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { I18n } from 'common/i18n';
import { Carriers } from 'services/carriers';
import { Notifier } from 'common/ui';
import { Content } from 'common/content';
import { Products } from 'services/products';

export class EditProduct {
    static inject = [DialogController, NewInstance.of(ValidationController), I18n, Carriers, Notifier, Content, Products];
    _products;
    _sso;

    title = '';
    showDeleteConfirm = false;
    @observable lineOfBusiness;

    factorPremiumTypes;

    constructor(dialogController, validationController, i18n, carriers, notifier, content, products) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this._validationController = validationController;
        this._validationController.addRenderer(new BootstrapFormValidationRenderer());
        this._i18n = i18n;
        this._carriers = carriers;
        this._notifier = notifier;
        this._content = content;
        this._products = products;

        this.deleteText = this._i18n.tr('delete');

        ValidationRules
            .ensure('name').required()
            .ensure('existingPolicyImpactOption').required().when(m => m.requirePolicyOption)
            .ensure('existingPolicyImpactAfterDate').required().when(m => m.requirePolicyOption && m.existingPolicyImpactOption === 'after-date')
            .on(this);
        
        this.factorRules = ValidationRules
            .ensure(f1 => f1.factor).required().when(f1 => f1.premiumType)
            .ensure(f1 => f1.factor).satisfiesRule('decimalRange', 0, 50).when(f1 => f1.premiumType)
            .ensure(f1 => f1.premiumType).required().when(f1 => f1.factor || f1.factor === 0 || f1.factor === '0')
            .ensure(f1 => f1.maximumValue).satisfiesRule('decimalRange', 0, 1000000).when(f1 => f1.premiumType)
            .rules;
    }

    async activate(model) {
        try {
            await this._load();

            this.products = model.products;
            this.product = model.product;
            const aliases = model.product.aliases || [];
            this.aliases = [];
            aliases.forEach(a => this.aliases.push({ alias: a, focus: false }));
            this.name = model.product.name;
            this.alias = undefined;
            this.lineOfBusiness = model.product.lineOfBusiness;
            this.productType = model.product.productType;
            this.underwriting = model.product.underwriting;
            this.factors = model.product.factors;
            if (!this.factors) this.factors = JSON.parse(JSON.stringify(this.defaultFactors));

            this._originalFactors = JSON.parse(JSON.stringify(this.factors));

            this.factors.forEach(f => {
                this._validationController.addObject(f, this.factorRules);
            });
    
            this.title = this.product.id ? this.product.name : this._i18n.tr('add-product');

            this._validationController.reset();
        } catch (err) {
            console.log(err);
        }
    }

    lineOfBusinessChanged() {
        if (!this.productTypesByLOB) return;
        const data = this.productTypesByLOB.find(x => x.lineOfBusiness === this.lineOfBusiness);
        if (!data) this.productTypes = [];
        else this.productTypes = data.productTypes;
    }

    async _load() {
        try {
            const loaders = [
                this._products.typesByLOB(),
                this._products.factorPremiumTypes(),
                this._products.defaultFactors(),
            ];
            const data = await Promise.all(loaders);
            this.productTypesByLOB = data[0];
            this.factorPremiumTypes = data[1];
            this.defaultFactors = data[2];
            this.lineOfBusinessChanged();
        } catch (err) {
            console.log(err);
        }
    }

    factorChanged() {
        this._setRequireExistingPolicyOption();
    }

    _setRequireExistingPolicyOption() {
        if (this.product.policyCount === 0) return;
        if (this.factors.length !== this._originalFactors.length) { this.requirePolicyOption = true; return; }
        for (let factor in this.factors) {
            const originalFactor = this._originalFactors.find(x => x.creditType === factor.creditType);
            if (!originalFactor) { this.requirePolicyOption = true; return; }
            if (originalFactor.premiumType !== factor.premiumType || originalFactor.factor !== Number(factor.factor) || originalFactor.maximumValue !== factor.maximumValue) { this.requirePolicyOption = true; return; }
        }
    }

    async save() {
        if (this.saving) return;
        try {
            this._setRequireExistingPolicyOption();
            const v = await this._validationController.validate();
            if (!v.valid) {
                this._notifier.error('product-update-error');
                return;
            }
            this.saving = true;
            this.factors.forEach(f => {
                if (!f.premiumType || f.premiumType === 'null') f.premiumType = null;
                if (f.factor === '' || f.factor === undefined) f.factor = null;
            });
            const aliases = [];
            this.aliases.forEach(a => {
                if (a.alias) aliases.push(a.alias);
            });
            await this._products.save(this.product.id, this.product.carrier.id, this.name, aliases, this.lineOfBusiness, this.productType, this.underwriting, this.factors, this.existingPolicyImpactOption, this.existingPolicyImpactAfterDate);
            this._notifier.success('product-update-successful', { name: this.name });
            this.dialogController.ok({ productDeleted: false });
        } catch (err) { 
            console.log(err);
            this._notifier.generalError(err);
        } finally {
            this.saving = false;
        }
    }

    addAlias() {
        this.aliases.push({ alias: '', focus: true });
    }

    removeAlias(index) {
        try {
            this.aliases.splice(index, 1);
        } catch (err) {
            this._notifier.error(err);
        }
    }

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteText = this._i18n.tr('delete');
    }

    async deleteProduct() {
        if (!this.showDeleteConfirm) {
            this.showDeleteConfirm = true;
            this.deleteText = this._i18n.tr('delete-confirm');
            return;
        }

        if (this.product.policyCount > 0) {
            if (!this.moveToProductId) {
                this._notifier.error('delete-product-with-policies-error');
                return;
            }
        }

        this.showDeleteConfirm = false;
        if (this.deleting) return;
        try {
            this.deleting = true;
            await this._products.delete(this.product.id, this.moveToProductId);
            this._notifier.success('products-delete-successful', { name: this.product.name });
            this.dialogController.ok({ productDeleted: true });
        } catch(err) {
            this.resetDelete();
            this._notifier.generalError(err);
        } finally {
            this.deleting = false;
        }
    }
}
