import { PLATFORM } from 'aurelia-pal';
import { I18n } from 'common/i18n';

export class Index {
	static inject = [I18n];
	_i18n;

	constructor(i18n) {
		this._i18n = i18n;
	}

	configureRouter(config, router){
		config.map([
            {
				route: '',
				moduleId: PLATFORM.moduleName('members/crm/client-list'),
				nav: false,
				membersOnly: true,
				adminOnly: false,
				settings: { canAddToMobileMenu: true },
			},
            {
				route: ['client/:id', 'client/:id/:view'],
				moduleId: PLATFORM.moduleName('members/crm/client'),
				nav: false,
				membersOnly: true,
				adminOnly: false,
				settings: { canAddToMobileMenu: false },
			},
            {
				route: 'nylas-address-book',
				moduleId: PLATFORM.moduleName('members/crm/nylas/address-book'),
				nav: false,
				membersOnly: true,
				adminOnly: false,
				settings: { canAddToMobileMenu: false },
			},
		]);

		this.router = router;
	}
}
