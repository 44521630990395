import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogController } from 'aurelia-dialog';
import Cropper from 'cropperjs';
import { screenSize } from 'common/ui';

export class EditProfileImage {
    static inject = [EventAggregator, DialogController];

    height;
    width;

    constructor(ea, dialogController) {
        this._ea = ea;
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
    }

    activate(model) {
        this._editorFile = model.file;
        this.height = model.height || 256;
        this.width = model.width || 256;
    }

    attached() {
        if (this.editingImage) {
            this.editingImage.remove();
            this.editingImage = undefined;
        }
        this.editingImage = new Image();
        this.editingImage.src = URL.createObjectURL(this._editorFile);
        this.imageEditorEl.appendChild(this.editingImage);

        if (this._cropper) {
            this.clearEditor();
        }
        const screen = screenSize(100, 200);
        const options = {
            aspectRatio: 1,
            minContainerWidth: screen.width || 300,
            minContainerHeight: screen.height || 550,
            minCropBoxWidth: 200,
            minCropBoxHeight: 200,
            dragMode: 'move',
            viewMode: 2,
        };
        this._cropper = new Cropper(this.editingImage, options);
    }

    profileEditComplete() {
        const me = this;
        // Get the canvas with image data from Cropper.js
        const canvas = this._cropper.getCroppedCanvas({
            width: this.width,
            height: this.height
        });
        // Turn the canvas into a Blob (file object without a name)
        canvas.toBlob((blob) => {
            this.dialogController.ok({ blob: blob });
            this.clearEditor();
        });
    }

    clearEditor() {
        if (!this._cropper) return;
        this._cropper.clear();
        this._cropper.destroy();
        this._cropper = undefined;
    }

    rotateRight() {
        this._cropper.rotate(90);
    }

    rotateLeft() {
        this._cropper.rotate(-90);
    }
}
