import { PLATFORM } from 'aurelia-pal';
import { observable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogController } from 'aurelia-dialog';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { I18n } from 'common/i18n';
import { Carriers } from 'services/carriers';
import { Notifier } from 'common/ui';
import { Content } from 'common/content';
import { Products } from 'services/products';
import { Sso } from 'services/sso';
import { DialogService } from 'aurelia-dialog';
import { EditProduct } from './edit-product';
import { EditCarrierPolicyStatus } from './edit-carrier-policy-status';
import { c } from 'common/common';
import environment from './../../../../config/environment.json';
PLATFORM.moduleName('./edit-carrier-policy-status');
PLATFORM.moduleName('./edit-product');

export class CarrierEditor {
    static inject = [EventAggregator, DialogController, NewInstance.of(ValidationController), I18n, Carriers, Notifier, Content, Products, Sso, DialogService];
    _products;
    _sso;
    _dialogService;

    title = '';
    showDeleteConfirm = false;

    id = null;
    isActive = false;
    name = '';
    code = '';
    logoUrl;
    websiteUrl = '';
    ordering;
    lifeProducts = false;
    annuityProducts = false;
    websiteUrl = '';
    getContracted = '';
    beginEApp = '';
    paperApps = '';
    agentServicesPhoneNumber = '';
    customerServicePhoneNumber = '';
    email = '';
    suranceBayData;
    newContractEmail;

    listTypes = [];
    @observable listType;
    linkLists;
    linkList;

    defaultLinkLists = [
        { code: 'quoting', links: [] },
        { code: 'quick-notes', links: [] },
        { code: 'product-tutorials', links: [] },
        { code: 'underwriting', links: [] },
        { code: 'videos', links: [] },
    ];

    tab = 'carrier';

    policyStatusFilters = [{ value: '', keys: ['lpfnStatusName', 'carrierStatus'] }];
    productFilters = [{ value: '', keys: ['name', 'lineOfBusinessName', 'aliases'] }];

    _handlers = [];

    constructor(ea, dialogController, validationController, i18n, carriers, notifier, content, products, sso, dialogService) {
        this._ea = ea;
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this._validationController = validationController;
        this._validationController.addRenderer(new BootstrapFormValidationRenderer());
        this._i18n = i18n;
        this._carriers = carriers;
        this._notifier = notifier;
        this._content = content;
        this._products = products;
        this._sso = sso;
        this._dialogService = dialogService;

        this.deleteText = this._i18n.tr('delete');

        ValidationRules
            .ensure('name').required()
            .ensure('ordering').required().satisfiesRule('integerRange', 1, 5000)
            .ensure('newContractEmail').email()
            .on(this);
    }

    activate(model) {
        this.id = null;
        this.isActive = false;
        this.hideOnAgentSite = false;
        this.notifyUplineNotContracted = true;
        this.name = '';
        this.code = '';
        this.logoUrl = '';
        this.lifeProducts = false;
        this.annuityProducts = false;
        this.websiteUrl = '';
        this.getContracted = '';
        this.beginEApp = '';
        this.paperApps = '';
        this.agentServicesPhoneNumber = '';
        this.customerServicePhoneNumber = '';
        this.email = '';
        this.newContractEmail = '';
        this.linkLists = this.defaultLinkLists;
        this.suranceBayData = { affiliations: [] };
        this.title = this._i18n.tr('carriers-add-title');
        if (model) {
            this.id = model.id;
            this.name = model.name;
            this.code = model.code;
            this.logoUrl = `${environment.static}/${model.logoUrl}`;
            this.title = model.name;
            this.isActive = model.isActive;
            this.hideOnAgentSite = model.hideOnAgentSite;
            this.notifyUplineNotContracted = model.notifyUplineNotContracted;
            this.lifeProducts = model.lifeProducts;
            this.annuityProducts = model.annuityProducts;
            this.websiteUrl = model.metadata.websiteUrl;
            this.getContracted = model.metadata.getContracted;
            this.beginEApp = model.metadata.beginEApp;
            this.paperApps = model.metadata.paperApps;
            this.agentServicesPhoneNumber = model.metadata.agentServicesPhoneNumber;
            this.customerServicePhoneNumber = model.metadata.customerServicePhoneNumber;
            this.email = model.metadata.email;
            this.newContractEmail = model.metadata.newContractEmail;
            if (model.linkLists.length) {
                this.linkLists = model.linkLists;
                // Add a template for any of the link lists from the default (for when new lists are added after the carriers are saved)
                this.defaultLinkLists.forEach(dll => {
                    const hasLL = this.linkLists.find(x => x.code === dll.code);
                    if (hasLL) return;
                    this.linkLists.push(dll);
                });
            }
            this.suranceBayData = model.suranceBayData || { affiliations: [] };
        }

        this.listTypes = this.linkLists.map(x => x.code);
        this.linkList = this.linkLists[0];

        this._validationController.reset();
        this._handlers.push(this._ea.subscribe('dnd:didEnd', async() => await this._saveLinkList()));
        this._handlers.push(this._ea.subscribe('lpfn.carrier.logo-updated', (data) => this._logoUpdated(data)));
    }

    attached() {
        this._loadPolicy();
    }

    deactivate() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    async _loadPolicy() {
        try {
            this.canDelete = false;
            if (!this.id) return;
            const policyCount = await this._carriers.policyCount(this.id);
            this.canDelete = policyCount.count === 0;
        } catch (err) {
            console.log(err);
        }
    }

    displayTab(key) {
        this.tab = key;
        if (this.tab === 'policy-statuses') this._loadPolicyStatuses();
        if (this.tab === 'products') this._loadProducts();
        if (this.tab === 'surance-bay') this._loadSuranceBay();
    }

    async _loadPolicyStatuses() {
        try {
            if (this.policyStatuses) return;
            this.loading = true;
            this.policyStatuses = await this._carriers.policyStatuses(this.id);
            this.policyStatuses.forEach(ps => ps.lpfnStatusName = this._i18n.tr(`policy-status-${ps.lpfnStatus}`));
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    async _loadProducts() {
        try {
            this.loading = true;
            if (this.products) return;
            this.products = await this._products.list(this.id);
            this.products.forEach(ps => ps.lineOfBusinessName = this._i18n.tr(`lob-${ps.lineOfBusiness}`));
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    async _loadSuranceBay() {
        try {
            if (this.affiliations) return;
            const sbAffiliates = await this._sso.suranceBayAffiliates();
            this.affiliations = [];
            sbAffiliates.forEach(a => {
                const match = this.suranceBayData.affiliations.find(x => x === a);
                this.affiliations.push({ code: a, checked: match ? true : false });
            });
        } catch (err) {
            console.log(err);
        }
    }

    async saveSuranceBay() {
        try {
            if (this.saving) return;
            this.saving = true;
            const data = { affiliations: [] };
            this.affiliations.forEach(a => {
                if (a.checked) data.affiliations.push(a.code);
            });
            await this._carriers.saveSuranceBayData(this.id, data);
            this._notifier.success('carrier-surance-bay-data-saved', { name: this.name });
        } catch (err) {
            console.log(err);
            this._notifier.generalError();
        } finally {
            this.saving = false;
        }
    }

    _logoUpdated(data) {
        if (!data || !data.id === this.id) return;
        this.logoUrl = `${environment.static}/${data.logoUrl}?uid=${(new Date()).getTime()}`;
    }

    async save() {
        if (this.saving) return;
        try {
            const v = await this._validationController.validate();
            if (!v.valid) return;
            this.saving = true;

            const metadata = {
                websiteUrl: this.websiteUrl,
                getContracted: this.getContracted,
                beginEApp: this.beginEApp,
                paperApps: this.paperApps,
                agentServicesPhoneNumber: this.agentServicesPhoneNumber,
                customerServicePhoneNumber: this.customerServicePhoneNumber,
                email: this.email,
                newContractEmail: this.newContractEmail
            };

            var saveModel = {
                name: this.name,
                code: this.code,
                isActive: this.isActive,
                hideOnAgentSite: this.hideOnAgentSite,
                notifyUplineNotContracted: this.notifyUplineNotContracted,
                lifeProducts: this.lifeProducts,
                annuityProducts: this.annuityProducts,
                ordering: this.ordering,
                metadata,
            };

            if (this.id) {
                await this._carriers.update(this.id, saveModel);
                this._notifier.success('carriers-update-successful', { name: this.name });
                this.triggerMenuUpdate();
                this.dialogController.ok({ carrierDeleted: false });
            } else {
                const carrier = await this._carriers.add(saveModel);
                this._notifier.success('carriers-add-successful', { name: this.name });
                this.id = carrier.id;
                this.triggerMenuUpdate();
            }
        } catch (err) {
            this._displayError(err);
        } finally {
            this.saving = false;
        }
    }

    _displayError(error) {
        var errorText = this._i18n.tr(error);
        if (errorText !== error) this._notifier.error(errorText);
        else this._notifier.generalError();
    }

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteText = this._i18n.tr('delete');
    }

    async deleteCarrier() {
        if (!this.showDeleteConfirm) {
            this.showDeleteConfirm = true;
            this.deleteText = this._i18n.tr('delete-confirm');
            return;
        }

        this.showDeleteConfirm = false;
        if (this.deleting) return;
        try {
            this.deleting = true;
            await this._carriers.delete(this.id);
            this._notifier.success('carriers-delete-successful', { name: this.name });
            this.triggerMenuUpdate();
            this.dialogController.ok({ carrierDeleted: true });
        } catch(err) {
            this.resetDelete();
            this._displayError(err);
        } finally {
            this.deleting = false;
        }
    }

    listTypeChanged() {
        this.linkList = this.linkLists.find(x => x.code === this.listType);
    }

    addLinkListItem() {
        this.linkList.links.push({
            type: 'link',
            name: '',
            src: '',
            description: '',
            fileSrc: '',
            editing: true,
            isNew: true,
        });
    }

    editLinkListItem(ll) {
        ll.editing = true;
        ll.original = JSON.parse(JSON.stringify(ll));
    }

    async deleteLinkListItem(index) {
        this.linkList.links.splice(index, 1);
        await this._saveLinkList();
    }

    closeLinkListItem(listItem, index) {
        listItem.editing = false;
        if (listItem.isNew) {
            this.linkList.links.splice(index, 1);
            return;
        }
        window.setTimeout(() => {
            listItem.type = listItem.original.type;
            listItem.name = listItem.original.name;
            listItem.src = listItem.original.src;
            listItem.description = listItem.original.description;
            listItem.fileSrc = listItem.original.fileSrc;
            delete listItem.original;
        }, 100);
    }

    async saveLinkListItem(listItem) {
        listItem.editing = false;
        listItem.isNew = false;
        await this._saveLinkList();
    }

    async _saveLinkList() {
        try {
            this.savingLinkList = true;
            const editing = this.linkLists.find(x => x.links.find(y => y.editing));
            if (editing) return;
            const cleanLinkLists = JSON.parse(JSON.stringify(this.linkLists));
            cleanLinkLists.forEach(ll => ll.links.forEach(l => {
                delete l.editing;
                delete l.original;
            }));
            await this._carriers.saveLinkLists(this.id, cleanLinkLists);
        } catch (err) {
            console.log(err);
        } finally {
            this.savingLinkList = false;
        }
    }

    openFileManager(pathProperty, object, nameProperty) {
        this._content.fileManager(file => {
            const name = file.displayName || file.fileName;
            if (object) {
                object[pathProperty] = file.path;
                if (nameProperty) object[nameProperty] = name;
            } else {
                this[pathProperty] = file.path;
                if (nameProperty) this[nameProperty] = name;
            }
        });
    }

    openStatusEditor(status) {
        if (status && status.policyCount > 0) {
            this._notifier.error('carrier-policy-status-cannot-edit');
            return;
        }
        const model = {
            isNew: true,
            carrierId: this.id,
            carrierName: this.name,
            status: '',
            lpfnStatus: '',
        };
        if (status) {
            model.isNew = false,
            model.status = status.carrierStatus,
            model.lpfnStatus = status.lpfnStatus;
        }
        this._dialogService.open({ viewModel: EditCarrierPolicyStatus, model }).whenClosed(response => {
            if (response.wasCancelled) return;
            this.policyStatuses = undefined;
            this._loadPolicyStatuses();
        });
    }

    openProductEditor(product) {
        if (!product) {
            product = {
                carrier: { id: this.id, name: this.name }
            };
        }
        this._dialogService.open({ viewModel: EditProduct, model: { product, products: this.products } }).whenClosed(response => {
            if (response.wasCancelled) return;
            this.products = undefined;
            this._loadProducts();
        });
    }

    triggerMenuUpdate() {
        this._carriers.clear();
        this._ea.publish(c.EventKeys.menu.reset, { menu: 'members', fireEvent: true });
    }
}
