import { PLATFORM } from 'aurelia-pal';
import { bindable } from 'aurelia-framework';
import { MemberAssistantLogins } from 'services/member-assistant-logins';
import { Security } from 'common/security';
import { DialogService } from 'aurelia-dialog';
import { EditMemberAssistantLogin } from './edit-member-assistant-login';
PLATFORM.moduleName('./edit-member-assistant-login');

export class MemberAssistantLogin {
    static inject = [MemberAssistantLogins, Security, DialogService];
    _memberAssistantLogins;
    _security;
    _dialogService;
    @bindable memberId;
    isViewingMyProfile;

    constructor(memberAssistantLogins, security, dialogService) {
        this._memberAssistantLogins = memberAssistantLogins;
        this._security = security;
        this._dialogService = dialogService;
    }

    attached() {
        this.isViewingMyProfile = this._security.isAuthenticatedMember(this.memberId);
        this._initialize();
    }

    memberIdChanged() {
        this._initialize();
    }


    async _initialize() {
        if (!this.memberId) return;
        try {
            this.assistant = await this._memberAssistantLogins.forMember(this.memberId);
            this.firstName = this.assistant.firstName;
            this.lastName = this.assistant.lastName;
            this.email = this.assistant.email;
        } catch (err) {
            console.log(err);
        }
    }

    openEditor() {
	    this._dialogService.open({ viewModel: EditMemberAssistantLogin, model: { assistant: this.assistant || {}, showEditor: true, showPasswordEditor: false }, ignoreTransitions: true }).whenClosed(response => {
	        if (response.wasCancelled) return;
	        this._initialize();
	    });
    }

    openPasswordEditor() {
	    this._dialogService.open({ viewModel: EditMemberAssistantLogin, model: { assistant: this.assistant || {}, showEditor: false, showPasswordEditor: true }, ignoreTransitions: true });
    }
}
