import { activationStrategy } from 'aurelia-router';
import { Router } from 'aurelia-router';
import { Page, Device } from 'common/ui';
import { Api } from 'common/server';
import { LpfnUtil } from 'common/utils';

export class Dashboard {
    static inject = [Router, Page, Device, Api];
    _router;
    _page;
    _device;
    _api;

    bigButtonView = false;

	constructor(router, page, device, api) {
        this._router = router;
	    this._page = page;
        this._device = device;
        this._api = api;

   }

	determineActivationStrategy () {
		return activationStrategy.invokeLifecycle;
	}

	activate(model, currentRoute) {
        try {
            this.hideContent = false;
            this.dashboardKey = model ? model.dashboardKey : undefined;
            this._setBigButtonView(currentRoute ? currentRoute.route : undefined);
            this._page.track('members/dashboard');
        } catch (err) {
            console.log(err);
        }
	}

    async attached() {
        try {
            LpfnUtil.addResizeHandler(this._setBigButtonView);
            // Make this call since the dashboard widgets won't redirect on a 401
            await this._api.get('security/auth');
        } catch (err) {}
    }

    detached() {
        LpfnUtil.removeResizeHandler(this._setBigButtonView);
    }

    _setBigButtonView = (currentRoute) => {
        const isMainDashboard = currentRoute !== undefined && currentRoute === '';
        if (!isMainDashboard) {
            this.bigButtonView = false;
        } else {
            this.bigButtonView = this._device.isMobileSize();
        }
    }
    
    deactivate() {
        this.hideContent = true;
    }
}
