import { CRM } from 'services/crm';

export class ClientList {
    static inject = [CRM];
    _crm;

    clientFilters = [{ value: '', keys: ['firstName', 'lastName', 'city', 'state', 'email', 'type'] }];

    constructor(crm) {
        this._crm = crm;
    }

    attached() {
        this._load();
    }

    async _load() {
        try {
            this.clients = await this._crm.clients();
            this._crm.setNavList(this.clients);
        } catch (err) {
            console.log(err);
            this._crm.clearNav();
        }
    }
}
