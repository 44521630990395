import { PLATFORM } from 'aurelia-pal';
import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import Dropzone from 'dropzone';
import { Notifier } from 'common/ui';
import { Security } from 'common/security';
import { I18n } from 'common/i18n';
import moment from 'moment';
import { EditProfileImage } from './dialog/edit-profile-image';
import { DialogService } from 'aurelia-dialog';
import { c } from 'common/common';
import environment from '../../../config/environment.json';
PLATFORM.moduleName('./dialog/edit-profile-image');

export class ProfileImageUpload {
    static inject = [Element, EventAggregator, Notifier, Security, I18n, DialogService]
    _ea;
    _notifier;
    _security;
    _i18n;
    _dialogService;

    @bindable({ changeHandler: '_resetFormAction' }) agentId;
    @bindable formId = 'profile-image-upload-dropzone';
    @bindable imgSrc;
    @bindable({ changeHandler: '_resetFormAction' }) uploadTo = 'api/member/upload-profile-image';
    @bindable height = 256;
    @bindable width = 256;
    @bindable loadEditor = true;
    @bindable instructionsKey = 'profile-image-upload-instructions';
    @bindable acceptedFiles = '.jpg,.jpeg,.heic,.png,.svg,.webp';
    @bindable dragContentKey = 'profile-image-upload';

    imageDropzone = null;
	displayClearButton = false;

	constructor(element, ea, notifier, security, i18n, dialogService) {
        this._element = element;
		this._ea = ea;
		this._notifier = notifier;
		this._security = security;
		this._i18n = i18n;
        this._dialogService = dialogService;
    }

	attached() {
		this.initializeDropzone();
	}

    bind() {
        this._resetFormAction();
        this.imgSrcChanged();
    }

    _resetFormAction() {
        if (!this.uploadTo) return;
        this.formAction = this._formAction();
        console.log('_resetFormAction', this.formAction);
        if (this.imageDropzone) {
            this.imageDropzone.options.url = this.formAction;
        }
    }

    _formAction() {
        let uploadTo = `${this.uploadTo.indexOf('http') < 0 ? environment.api : ''}/${this.uploadTo}`
        if (this.uploadTo.indexOf('api/member/upload-profile-image') < 0) return uploadTo;
        if (this.agentId && !this._security.isAuthenticatedMember(this.agentId)) uploadTo += `?memberId=${encodeURIComponent(this.agentId)}`;
        return uploadTo;
    }

	initializeDropzone() {
		const me = this;
		const d = new Date();
		const timezoneOffset = d.getTimezoneOffset();

        if (this.imageDropzone) {
            return;
        }
        const dictDefaultMessage = this._i18n.tr(this.instructionsKey);
        const url = this._formAction();
        console.log('initialize', url);
		const myDropzone = new Dropzone(`#${this.formId}`, {
            url,
            dictDefaultMessage: dictDefaultMessage,
			paramName: 'file',
			maxFilesize: 10, // MB
			headers: { 'Authorization': `Bearer ${this._security.token}`, 'X-LEGACY-TimezoneOffset': timezoneOffset },
			acceptedFiles: this.acceptedFiles,
			maxFiles: 1,
            clickable: this.profileImageEl,
			init: function () {
				this.on('success', function (file, response) {
					this.removeFile(file);
					me._notifier.success('profile-image-updated-message');
                    if (me.uploadTo.indexOf('api/member/upload-profile-image') >= 0 && me.agentId) {
                        me._ea.publish(c.EventKeys.agent.profileImageUpdated, { agentId: me.agentId });
                    }
                    me._element.dispatchEvent(new CustomEvent('uploaded', { bubbles: true, detail: {} }));
                    me._resetImgSrc();
				});
				this.on('error', function (file, error, x) {
                    if (file.status === 'canceled') return;
				    console.log(error);
				    me.displayClearButton = true;
				    if (error === 'You can\'t upload files of this type.') me._notifier.error('profile-image-upload-error-file-type');
                    else me._notifier.error("profile-image-upload-error", false, { message: error });
				});
			},
            transformFile: function(file, done) {
                if (file.name.indexOf('.heic') >= 0) {
                    me._heicToJpg(file).then(jpgFile => {
                        if (me.loadEditor) me._loadEditor(jpgFile, done);
                        else done(file);
                    }).catch(err => {
                        me.displayClearButton = true;
                        me._notifier.error('profile-image-upload-error', { message: err });
                        done();
                    });
                } else {
                    if (me.loadEditor) me._loadEditor(file, done);
                    else done(file);
                }
            },
            renameFile: function(file) {
                return 'profile-image-upload.jpg';
            },
        });

		this.imageDropzone = myDropzone;
	}

    _heicToJpg(file) {
        return new Promise((resolve, reject) => {
            try {
                heic2any({ blob: file, toType: 'image/jpeg', quality: 1 }).then(jpgBlob => {
                    const jpgFile = new File([jpgBlob], file.name.replace('.heic', '.jpg'));
                    resolve(jpgFile);
                }).catch(e => {
                    reject(e);
                });
            } catch (err) {
                reject(err);
            }
        });
    }

    imgSrcChanged() {
        if (!this.imgSrc) return;
        this.previewImgSrc = `${this.imgSrc.indexOf('http') < 0 ? environment.static : ''}${this.imgSrc}`;
        this._resetImgSrc();
    }
    
    _resetImgSrc() {
        let imgSrc = this.previewImgSrc;
        if (imgSrc.indexOf('?') >= 0) imgSrc += `&cache-buster=${moment().valueOf()}`;
        else imgSrc += `?cache-buster=${moment().valueOf()}`;
        window.setTimeout(() => {
            if (!this.profileImageEl) return;
            let imgSrcNoCacheBuster = imgSrc;
            if (imgSrc.indexOf('?') >= 0) imgSrcNoCacheBuster = imgSrc.substr(0, imgSrc.indexOf('?'));
            this.profileImageEl.setAttribute('src', imgSrc);
        }, 500);
    }

    clearZone() {
		this.imageDropzone.removeAllFiles(true);
		this.displayClearButton = false;
	}

    _loadEditor(file, done) {
        this._editorFile = file;
        this._editorDone = done;
	    this._dialogService.open({ viewModel: EditProfileImage, model: { file, height: this.height, width: this.width }, ignoreTransitions: true }).whenClosed(async(response) => {
	        if (response.output && response.output.blob) {
                // Create a new Dropzone file thumbnail
                const me = this;
                this.imageDropzone.createThumbnail(
                    response.output.blob,
                    this.imageDropzone.options.thumbnailWidth,
                    this.imageDropzone.options.thumbnailHeight,
                    this.imageDropzone.options.thumbnailMethod,
                    false,
                    function(dataURL) {
                        // Update the Dropzone file thumbnail
                        me.imageDropzone.emit('thumbnail', me._editorFile, dataURL);
                        // Return the file to Dropzone
                        me._editorDone(response.output.blob);
                    },
                );
            }
	    });
    }
} 

 