export class Index {
    configureRouter(config, router){
        config.title = 'LPFN';
        config.auth = true;
        config.map([
                { route: '', moduleId: 'members/calculators/income', nav: false, membersOnly: true, adminOnly: false, settings: { canAddToMobileMenu: true } },
        ]);
        this.router = router;
    }

    navigate(key, route) {
        this.current = key;
        this.router.navigate(route, true);
    }
}
