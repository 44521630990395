import {inject, bindable} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import Dropzone from 'dropzone';
import {Notifier} from 'common/ui';
import {Security} from 'common/security';
import {I18n} from 'common/i18n';
import environment from './../../../../config/environment.json';

@inject(EventAggregator, Notifier, Security, I18n)
export class CarrierLogoUpload {

    @bindable id = null;
    imageDropzone = null;
    displayClearButton = false;


    constructor(eventAggregator, notifier, security, i18n) {
        this.eventAggregator = eventAggregator;
        this.notifier = notifier;
        this.security = security;
        this.i18n = i18n;
    }

    bind() {
        this._setUrl();
    }

    attached() {
        this.initializeDropzone();
    }

    detached() {
        if (!this.imageDropzone) return;
        this.imageDropzone = undefined;
    }
    
    idChanged() {
        this._setUrl();
    }

    _setUrl() {
        if (!this.imageDropzone || !this.id) return;
        this.imageDropzone.options.url = `${environment.api}/api/carrier/${encodeURIComponent(this.id)}/upload-logo`;
    }

    initializeDropzone() {
        let me = this;
        let d = new Date();
        let timezoneOffset = d.getTimezoneOffset();

        let myDropzone = new Dropzone('#carrier-logo-upload-dropzone', {
            url: `${environment.api}/api/carrier/${this.id}/upload-logo`,
            dictDefaultMessage: this.i18n.tr('carriers-drop-logo'),
            paramName: 'file',
            maxFilesize: 6, // MB
            headers: { 'Authorization': 'Bearer ' + this.security.token, 'X-LEGACY-TimezoneOffset': timezoneOffset },
            acceptedFiles: 'image/*',
            maxFiles: 1,
            init: function () {
                this.on('addedfile', function (file) {
                    //console.log('added file.');
                });
                this.on('success', function (file, response) {
                    this.removeFile(file);
                    me.notifier.success('carriers-logo-updated-message');
                    me.eventAggregator.publish('lpfn.carrier.logo-updated', response);
                });
                this.on('error', function (file, error, x) {
                    me.displayClearButton = true;
                    me.notifier.error('carriers-logo-upload-error', { message: error.message });
                });
            }
        });

        this.imageDropzone = myDropzone;
    }

    clearZone() {
        this.imageDropzone.removeAllFiles();
        this.displayClearButton = false;
    }
} 

 