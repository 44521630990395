import {inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {Api} from 'common/server';
import {m} from 'common/models';
//import $ from 'jquery';
import {Page, Notifier} from 'common/ui';

@inject(EventAggregator, Api, Page, Notifier)
export class HiearchyUpline {

	isAttached = false;
	viewMemberId = null;
	isViewingMyProfile = true;

	memberUpline = [];

	constructor(eventAggregator, api, page, notifier) {
		this.eventAggregator = eventAggregator;
		this.api = api;
		this.page = page;
		this.notifier = notifier;
	}

	attached() {
		//console.log('hierarchy-upline.attached');
		this.isAttached = true;
		if ($('team-member-hierarchy-upline').length > 0) {
			this.viewMemberId = $('team-member-hierarchy-upline').attr('data-member-id');
			this.isViewingMyProfile = false;
		}

		this.initializeTree();
	}

	activate(params, routerConfig) {
		//console.log('hierarchy-upline.activate');
		if (params.memberId) {
			this.viewMemberId = params.memberId;
			this.isViewingMyProfile = false;
			if (this.isAttached) this.initializeTree();
		}
	}

	initializeTree() {
		var self = this;
		var treeContainer = $('#my-team-tree');
		self.api.getData('member/' + self.viewMemberId + '/upline-list').then(data => {
			self.memberUpline = data;
		}).catch(error => {
		    self.notifier.generalError();
		});
	}

	showProfile(uplineMember) {
	    this.eventAggregator.publish("lpfn.show-member-profile-popup", { member: null, memberId: uplineMember.id });
	}

}
