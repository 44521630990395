import { DialogController } from 'aurelia-dialog';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { MemberAssistantLogins } from 'services/member-assistant-logins';
import { Notifier } from 'common/ui';
import { c } from 'common/common';

export class EditMemberAssistantLogin {
    static inject = [DialogController, MemberAssistantLogins, Notifier, NewInstance.of(ValidationController)];

    _memberAssistantLogins;
    _notifier;

    currentPassword = '';
   	newPassword = '';

    constructor(dialogController, memberAssistantLogins, notifier, validationController) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this._memberAssistantLogins = memberAssistantLogins;
        this._notifier = notifier;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());

        ValidationRules
            .ensure('currentPassword').required()
            .ensure('newPassword').required().minLength(6).matches(c.RegEx.strongPassword)
            .on(this);
    }

    activate(model) {
        this.titleKey = model.showEditor
            ? model.assistant && model.assistant.id ? 'member-assistant-edit' : 'member-assistant-add'
            : 'member-assistant-set-password';
        this.showEditor = model.showEditor;
        this.showPasswordEditor = model.showPasswordEditor;
        this.assistant = model.assistant;
        this._clearEditor();
        if (this.showEditor) {
            this.firstName = model.assistant.firstName;
            this.lastName = model.assistant.lastName;
            this.email = model.assistant.email;
            this.password = undefined;
            this.password2 = undefined;
            this.focusFirstName = true;
            this.deleteTextKey = model.assistant.id ? 'delete' : '';
        }
        if (this.showPasswordEditor) {
            this.newPassword = undefined;
            this.newPassword2 = undefined;
            this.focusNewPassword = true;
        }
        this._setupValidation();
    }

    attached() {
        // Do not auto fill these, browser do not respect autocomplete attr
        window.setTimeout(() => {
            if (this.assistantPwdEl) this.assistantPwdEl.value = '';
            if (this.assistantPwd2El) this.assistantPwd2El.value = '';
            if (this.assistantNewPwdEl) this.assistantNewPwdEl.value = '';
            if (this.assistantNewPwd2El) this.assistantNewPwd2El.value = '';
        }, 750);
    }

    _setupValidation() {
        if (this.showEditor) {
            ValidationRules
                .ensure('firstName').required()
                .ensure('lastName').required()
                .ensure('email').required().email()
                .ensure('password').required().minLength(6).matches(c.RegEx.strongPassword).when(x => !x.assistant.id)
                .ensure('password2').required().minLength(6).matches(c.RegEx.strongPassword).satisfiesRule('matchesProperty', 'password').when(x => !x.assistant.id)
                .on(this);
        } else if (this.showPasswordEditor) {
            ValidationRules
                .ensure('newPassword').required().minLength(6).matches(c.RegEx.strongPassword)
                .ensure('newPassword2').required().minLength(6).matches(c.RegEx.strongPassword).satisfiesRule('matchesProperty', 'newPassword')
                .on(this);
        }

        this._validationRenderer = new BootstrapFormValidationRenderer();
        this.validationController.addRenderer(this._validationRenderer);
        this.validationController.reset();
    }

    _tearDownValidation() {
        if (!this._validationRenderer) return;
        this.validationController.removeRenderer(this._validationRenderer);
    }

    _clearEditor() {
        this._tearDownValidation();
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.password = '';
        this.password2 = '';
        this.newPassword = '';
        this.newPassword2 = '';
    }

    closeEditor() {
        this._clearEditor();
        this.dialogController.cancel();
    }

    async save() {
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;
            const isNew = this.assistant.id ? false : true;
            await this._memberAssistantLogins.save(this.assistant.id, this.email, this.password, this.firstName, this.lastName);
            this._notifier.success(isNew ? 'assistant-login-created' : 'assistant-login-updated');
            this._clearEditor();
            this.dialogController.ok();
        } catch (err) {
            console.log(err);
            this._notifier.error(err);
        }
    }

    async changePassword() {
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;
            await this._memberAssistantLogins.changePassword(this.assistant.id, this.newPassword);
            this._notifier.success('assistant-login-password-changed');
            this._clearEditor();
            this.dialogController.ok();
        } catch (err) {
            console.log(err);
            this._notifier.error(err);
        }
    }

    async delete() {
        if (!this.showDeleteConfirm) {
            this.deleteTextKey = 'delete-confirm';
            this.showDeleteConfirm = true;
            return;
        }
        try {
            this.deleting = true;
            this.showDeleteConfirm = false;
            await this._memberAssistantLogins.delete(this.assistant.id);
            this._notifier.success('assistant-login-removed');
            this._clearEditor();
            this.dialogController.ok();
        } catch (err) {
            console.log(err);
            this._notifier.error(err);
        } finally {
            this.deleting = false;
        }
    }

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteTextKey = 'delete';
    }
}