import { bindable } from 'aurelia-framework';
import { Security } from 'common/security';
import { Notifier } from 'common/ui';
import { Sso } from 'services/sso';
import { Members } from 'services/members';

export class SuranceBay {
    static inject = [Security, Notifier, Sso, Members];
    @bindable member = null;
    @bindable showDetails = false;
    @bindable allowToggle = true;

    _security;
    _notifier;
    _sso;
    _members;

    affiliate;

    constructor(security, notifier, sso, members) {
        this._security = security;
        this._notifier = notifier;
        this._sso = sso;
        this._members = members;
    }

    bind() {
        this._initialize();
    }

    memberChanged() {
        this._initialize();
    }

    async _initialize() {
        if (!this.member) return;
        try {
            const agency = await this._members.getAgency(this.member.id);
            const isTheAgency = agency && agency.id === this._security.authenticatedMemberId;
            const isInDownline = await this._members.isInDownline(this.member.id);
            this.canChange = isTheAgency || (isInDownline && this._security.isAgency());
            if (this._security.isAdmin) this.canChange = true;
            this.affiliate = this.member.suranceBayBranch || '';
        } catch (err) {
            console.log(err);
        }
    }

    attached() {
        this._load();
    }

    async _load() {
        try {
            this.affiliates = await this._sso.suranceBayAffiliates();
        } catch (err) {
            console.log(err);
        }
    }

    async saveAffiliate() {
        try {
            this.saving = true;
            await this._sso.saveSuranceBayAffiliate(this.member.id, this.affiliate);
            this._notifier.success('surancebay-affiliate-saved');
        } catch (err) {
            console.log(err);
            this._notifier.error('surancebay-affiliate-saved-error');
        } finally {
            this.saving = false;
        }
    }

    toggleDetails() {
        this.showDetails = !this.showDetails;
    }
}
