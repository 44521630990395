import { DialogController } from 'aurelia-dialog';
import { ProductionService } from 'services/production-service';
import { Carriers } from 'services/carriers';
import { Notifier } from 'common/ui';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';

export class EditCarrierPolicyStatus {
    static inject = [DialogController, ProductionService, Carriers, Notifier, NewInstance.of(ValidationController)];
    dialogController;
    _productionService;
    _carriers;
    _notifier;
    _validationController;

    _carrierId;
    carrierName;
    isNew;
    carrierStatus;
    lpfnStatus;
    deleteKey = 'delete';

    constructor(dialogController, productionService, carriers, notifier, validationController) {
        this.dialogController = dialogController;
        this._productionService = productionService;
        this._carriers = carriers;
        this._notifier = notifier;
        this._validationController = validationController;
        this._validationController.addRenderer(new BootstrapFormValidationRenderer());

        ValidationRules
            .ensure('carrierStatus').required()
            .on(this);
    }

    activate(model) {
        this.isNew = model.isNew;
        this._carrierId = model.carrierId;
        this.carrierName = model.carrierName;
        this.carrierStatus = model.status;
        this.lpfnStatus = model.lpfnStatus;
        this._load();
        this._validationController.reset();
    }

    async _load() {
        try {
            this.lpfnStatuses = await this._productionService.policyStatuses();
        } catch (err) {
            console.log(err);
        }
    }
    
    async save() {
        try {
            const v = await this._validationController.validate();
            if (!v.valid) return;
            this.saving = true;
            const response = await this._carriers.savePolicyStatus(this._carrierId, this.carrierStatus, this.lpfnStatus);
            if (response.succeeded) {
                this._notifier.success('add-carrier-policy-status-success');
                this.dialogController.ok();
                return;
            }
            this._notifier.error(response.message || 'add-carrier-policy-status-fail');
        } catch (err) {
            console.log(err);
        } finally {
            this.saving = false;
        }
    }

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteKey = 'delete';
    }

    async delete() {
        if (!this.showDeleteConfirm) {
            this.showDeleteConfirm = true;
            this.deleteKey = 'delete-confirm';
            return;
        }

        this.showDeleteConfirm = false;
        if (this.deleting) return;
        try {
            this.deleting = true;
            const response = await this._carriers.deletePolicyStatus(this._carrierId, this.carrierStatus);
            if (response.succeeded) {
                this.dialogController.ok();
                return;
            }
            this._notifier.error(response.message || 'An error occurred');
        } catch(err) {
            this.resetDelete();
            this._notifier.generalError();
        } finally {
            this.deleting = false;
        }
    }
} 

 