import { CRM } from 'services/crm';
import { Security } from 'common/security';
import { WIDGET, COMMISSION } from 'common/constants';

export class Client {
    static inject = [CRM, Security];
    _crm;

    client;
    view = 'overview';
    policies;
    showCommissions = false;
    commissionsConfig;

    constructor(crm, security) {
        this._crm = crm;
        this.showCommissions = security.canSeeCommissions();
    }

    activate(model) {
        if (this.id === model.id) return; // this is from changing the hash
        this.id = model.id;
        this.policies = undefined;
        this._load(model.view);
    }

    async _load(view) {
        try {
            this.overview = await this._crm.client(this.id);
            this.commissionsConfig = {
                clientId: this.id,
                groupBy: COMMISSION.GroupBy.Policy,
                widgetPeriod: WIDGET.Period.All,
            };
    
            this.nav = this._crm.getNav(this.id);
            if (view) this.display(view);
        } catch (err) {
            console.log(err);
        }
    }

    display(view) {
        this.view = view;
        if (this.view === 'policies') this._loadPolicies();
        window.location.hash = `/members/crm/client/${this.id}/${view}`;
    }

    async _loadPolicies() {
        try {
            if (this.policies) return;
            this.policies = await this._crm.clientPolicies(this.id);
        } catch (err) {
            console.log(err);
        }
    }
}